import { UserProfile, UserProfileInput } from '@cocoplatform/coco-rtc-shared';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { atom } from '@cocoplatform/coco-rtc-client';
import { reportServerError } from 'utils/report-error';
import { SyncState } from 'utils/sync-state';
import uniqueId from 'lodash/uniqueId';
import { useStore } from '@nanostores/react';
import { useAuthState } from 'utils/auth-state';
import { CocoLogger } from '@cocoplatform/coco-logger';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export interface UserProfileState {
  userProfile?: UserProfile;
  syncState: SyncState;
}

export const userProfileAtom = atom<UserProfileState>({
  syncState: 'pending',
});

export const useUserProfile = () => {
  const { _ } = useLingui();
  const profileState = useStore(userProfileAtom);
  const { setProfileCompletionState } = useAuthState();

  return {
    ...profileState,
    updateUserProfile,
    uploadUserAvatar,
    fetchUserProfile,
    updateLocation,
  };

  async function fetchUserProfile() {
    userProfileAtom.update((it) => ({ ...it, syncState: 'loading' }));
    try {
      const { data } = await httpClient.get('/user/profile');
      CocoLogger.init(data.id);
      if (data.profileUpdatedAt) {
        setProfileCompletionState('COMPLETE');
      }
      userProfileAtom.set({ userProfile: data, syncState: 'loaded' });
    } catch (error: any) {
      userProfileAtom.set({ syncState: 'failed' });
      // eslint-disable-next-line lingui/no-unlocalized-strings
      CocoLogger.error('Failed to fetch user profile', error);
    }
  }

  async function updateUserProfile(input: UserProfileInput) {
    try {
      const { data } = await httpClient.put('/user/profile', input);
      userProfileAtom.set({ userProfile: data, syncState: 'loaded' });
    } catch (error: any) {
      userProfileAtom.set({ syncState: 'failed' });
      reportServerError({
        error,
        title: _(msg`Failed to save user profile`),
      });
    }
  }

  async function uploadUserAvatar(avatarFile: File) {
    try {
      const formData = new FormData();
      formData.append('avatar', avatarFile);
      const {
        data: { avatarUrl },
      } = await httpClient.put(`/user/avatar/upload`, formData);
      userProfileAtom.update((s) => ({
        ...s,
        userProfile: s.userProfile
          ? {
              ...s.userProfile,
              avatarUrl: avatarUrl + '&v=' + uniqueId(),
            }
          : s.userProfile,
      }));
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to upload avatar`),
        error,
      });
    }
  }

  async function updateLocation(countryCode: string, city: string | null) {
    await httpClient.put('/user/profile', {
      countryCode,
      city,
    });
    userProfileAtom.update((prev) => ({
      ...prev,
      userProfile: prev.userProfile
        ? {
            ...prev.userProfile,
            countryCode,
            city,
          }
        : undefined,
    }));
  }
};
